<template>
  <div class="" v-loading="loading">
    <div class="header">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane
          v-for="(item, index) in cpu_tabList"
          :key="index"
          :name="item.activeName"
        >
          <template v-slot:label>
            <p class="tabItem-label" :class="'tabItem-label-' + index">
              <span>{{ item.name }}</span>
            </p>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content">
      <div v-if="activeName == 'flow'" style="padding: 20px">
        <p style="font-weight: 600">办理流程</p>
        <p>1、企业提交诉求</p>
        <p class="flowInfo">选择诉求目的，填写企业诉求</p>
        <p>2、主管部门跟进</p>
        <p class="flowInfo">责任单位、协办单位共同处理</p>
        <p>3、诉求办结反馈</p>
        <p class="flowInfo">用户中心多渠道反馈处理结果</p>
        <p>4、企业评价服务</p>
        <p class="flowInfo">多维度评价诉求服务</p>

        <p style="font-weight: 600">办理范围</p>
        <p>1、不属于我市行政管辖范围内的事项;</p>
        <p>2、不涉及市场主体业务的个人诉求事项;</p>
        <p>
          3、已进入或须通过诉讼、仲裁纪检监察、行政复议、政府信息公开等程序解决的事项;
        </p>
        <p>4、已进入信访渠道的事项;</p>
        <p>5、涉及国家秘密、商业秘密、个人隐私的事项;</p>
        <p>
          6、违反法律法规规章、社会公序良俗以及恶意攻击、骚扰或无实质诉求内容的事项;
        </p>
        <p>
          7、相关诉求正在办理过程中或已按照法律、法规、规章和有关政策规定办理完毕，诉求人仍以同一事实、同一理由重复反映的事项;
        </p>
        <p>8、其他依据法律、法规、规章不予受理的事项。</p>
        <p style="color: red">
          特别提醒：涉及民生等个人诉求请通过 12345 政务服务便民热线提出。
        </p>
      </div>
      <div v-if="activeName == 'question'" style="padding: 20px">
        <div
          class="questionItem"
          v-for="(item, index) in datalist"
          :key="index"
        >
          <el-tooltip effect="light" :content="item.content" placement="top">
            <p class="itemText" style="cursor: default">
              问题：【{{ item.deptName }}】{{ item.content }}
            </p>
          </el-tooltip>

          <el-tooltip
            style="width: 800px !important"
            effect="light"
            :content="item.answer"
            placement="top"
          >
            <p class="itemText">回答：{{ item.answer }}</p>
          </el-tooltip>
          <el-button
            type="primary"
            class="questionItem_btn"
            style="background: #4795f1; color: #fff"
            @click="judge(item)"
            >评价</el-button
          >
        </div>
        <!-- 页尾 -->
        <div class="pro-pagination" style="text-align: center">
          <el-pagination
            hide-on-single-page
            layout="total,prev,pager,next,jumper"
            background
            :total="totalSizes"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <NoDataView
          :dataState="
            !loading && (datalist == null || datalist.length == 0)
              ? 'noData'
              : 'init'
          "
        />
      </div>
    </div>
    <el-dialog
      title="评价"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="appealForm" label-width="100px">
        <el-form-item label="部门名称：">
          <el-input
            type="text"
            disabled
            v-model="appealForm.deptName"
          ></el-input>
        </el-form-item>
        <el-form-item label="诉求问题：">
          <el-input
            disabled
            type="textarea"
            autosize
            placeholder=""
            v-model="appealForm.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否匿名：">
          <el-radio-group v-model="appealForm.isAnonymous" disabled>
            <el-radio label="1">匿名</el-radio>
            <el-radio label="0">实名</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="诉求回复：">
          <el-input
            disabled
            type="textarea"
            maxlength="500"
            autosize
            show-word-limit
            placeholder=""
            v-model="appealForm.answer"
          ></el-input>
        </el-form-item>
        <el-form-item label="评价：">
          <el-input
            type="textarea"
            maxlength="500"
            rows="10"
            show-word-limit
            placeholder=""
            v-model="appealForm.evaluationContent"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" style="width: 200px" @click="onSubmit"
            >提交评价</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NoDataView from "@/components/nodata-view";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: { NoDataView },
  computed: {
    cpu_tabList() {
      return [
        { name: `办理流程及范围`, activeName: "flow" },
        // { name: `办理范围`, activeName: "range" },
        { name: `全部问题`, activeName: "question" },
      ];
    },
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  data() {
    return {
      datalist: [],
      loading: false,
      activeName: "flow",
      totalSizes: 10,
      pageSize: 5,
      pageNum: 1,
      dialogVisible: false,
      appealForm: {
        deptName: "",
        content: "",
        isAnonymous: "",
        answer: "",
        evaluationContent: "",
      },
      dataCache: {},
    };
  },
  created() {
    this.getQuestionList();
  },
  mounted() {},
  methods: {
    handleTabClick() {},
    handleCurrentChange() {},
    judge(row) {
      this.appealForm = row;
      this.dialogVisible = true;
      this.dataCache = row;
    },
    getNowDate(type) {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hh = now.getHours();
      let mm = now.getMinutes();
      let ss = now.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      if (type == "day") {
        return year + "-" + month + "-" + day;
      } else {
        return year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
      }
    },
    onSubmit() {
      let url = "/dev-api/sqDispose";
      let params = {
        evaluationPeople: this.userInfo.enterpriseInfo.name,
        evaluationContent: this.appealForm.evaluationContent,
        evaluationTime: this.getNowDate(),
        id: this.dataCache.id,
      };
      let that = this;
      this.$httpApi.put(url, params).then((res) => {
        if (res.code == 200) {
          that.$message.success("评价成功！");
          that.dialogVisible = false;
          that.getQuestionList();
        }
      });
    },
    getQuestionList() {
      this.loading = true;
      let url = `/dev-api/sqDispose/list?pageNum=${this.pageNum}&pageSize=${this.pageSize}&tyshxydm=${this.userInfo.enterpriseInfo.idcard}`;
      this.$httpApi.get(url, {}).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.datalist = res.rows;
          this.totalSizes = res.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
$tabColors: (
  "0": $col-blue,
  "1": #7e97ac,
  "2": $col-orange,
  "3": $col-red,
  "4": $col-green,
);
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  margin-bottom: 10px;
}
.content {
  width: 100%;
  //   min-height: 500px;
  background: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 0 20px;
  p {
    line-height: 32px;
  }
  .flowInfo {
    text-indent: 2rem;
  }
}
.questionItem {
  width: 100%;
  box-sizing: border-box;
  min-height: 80px;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px #f9f8f8;
  padding: 10px;
  position: relative;
  // z-index: 999;
  margin-bottom: 20px;
  border: 1px solid #f8f8f8;
  &:hover {
    box-shadow: 0 2px 4px 1px rgba($col-theme, 0.2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    .proshow-title {
      color: $col-theme !important;
    }
  }
  .itemText {
    cursor: pointer;
    width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .questionItem_btn {
    position: absolute;
    right: 20px;
    top: calc(50% - 20px);
    // top: 0;
    // float: right;
  }
}
::v-deep .el-tabs__header {
  padding-top: 5px;
  .el-tabs__nav-wrap {
    .el-tabs__item {
      padding-right: 20px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;
      .tabItem-label {
        position: relative;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        .tabItem-count {
          position: absolute;
          top: 50%;
          right: 0;
          display: inline-block;
          min-width: 16px;
          height: 16px;
          padding-right: 2px;
          padding-left: 2px;
          border-radius: 16px;
          margin-left: 5px;
          line-height: 16px;
          text-align: center;
          font-size: 12px;
          color: #ffffff;
          background-color: $col-red;
          -webkit-transform: translate(calc(100% + 2px), -50%);
          -moz-transform: translate(calc(100% + 2px), -50%);
          -o-transform: translate(calc(100% + 2px), -50%);
          -ms-transform: translate(calc(100% + 2px), -50%);
          transform: translate(calc(100% + 2px), -50%);
        }
        // @each $k, $v in $tabColors {
        //   &.tabItem-label-#{$k} .tabItem-count {
        //     background-color: $v;
        //   }
        // }
      }
      &:hover,
      &.is-active {
        color: $col-theme;
        // @each $k, $v in $tabColors {
        //   .tabItem-label-#{$k} {
        //     color: $v;
        //   }
        // }
      }
    }
    .el-tabs__active-bar {
      background-color: $col-theme;
    }
    &::after {
      display: none;
    }
  }
}
</style>
